.service-item {
  .service-header {
    height: 400px;
    width: 100%;
    .header-text {
      height: 150px;
      h4 {
        margin-top: 5px;
      }
    }
  }
}
.service-feature {
  padding: 30px;
  h4 {
    font-size: 30px;
    margin: 5px 0px;
  }
  h5 {
    font-size: 22px;
    font-weight: bold !important;
    margin: 10px 0px;
  }
  h6 {
    font-size: 18px !important;
    font-weight: bold !important;
    margin: 5px 0px;
  }
  li {
    margin: 0px !important;
    padding-left: 10px;
  }
  a {
    color: #0a2671 !important;
    text-decoration: underline !important;
    font-weight: normal !important;
  }
  .step-img {
    padding: 10px 0px;
    max-width: 1490px;
    width: 100%;
    min-width: 500px;
    object-fit: contain;
  }
  table {
    width: 100%;
    border: 1px solid black;
    th {
      padding: 10px;
    }
    td {
      padding: 20px;
      font-weight: bolder;
    }
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}
