.faqbox {
  .linkbutton {
    min-width: 200px;
    margin-left: 20px;
    border: 2px solid #bcbcbc;
    border-radius: 8px;
    padding: 13px 10px;
    font-size: 20px;
  }
  .accordion {
    margin: 10px 0px;
  }
  .faqtab button {
    border-color: transparent !important;
  }
  #panel1d-header {
    border-bottom: 1.5px solid#bcbcbc;
    h6 {
      font-size: 1em;
    }
  }
}
