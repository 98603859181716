.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.solution-item {
  max-width: 500px;
  height: 350px;
  width: 100%;
  border: 2px solid #bcbcbc;
  cursor: pointer;
  &:hover {
    border-color: #0a2761;
  }
  .solution-content {
    padding: 20px 20px;
    p {
      margin-top: 10px;
    }
  }
  img {
    padding: 20px 0px;
    width: 100%;
    height: 150px !important;
    object-fit: contain;
    border-bottom: 2px solid #bcbcbc;
  }
}
