.partner {
  .dataspace-tab {
    background-color: #aeaeae;
    color: black;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    padding: 0px;
    margin: 0px;
    button {
      background-color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      border-radius: 0px !important;
      border-bottom: 2px solid #aeaeae;
    }
  }
}

.partnersection {
  li {
    margin-top: 0px;
    margin-left: 20px;
    list-style: none;
    text-indent: -5px;
    &:before {
      content: "- ";
      text-indent: -5px;
    }
  }

  .list {
    display: block;
    margin-top: 10px;
  }
  .partner-roles-img {
    margin-top: 20px;
  }
  .partnerbox {
    padding: 20px !important;
    border: 2px solid #bcbcbc !important;
    border-radius: 16px !important;
    hr {
      margin: 15px 0px !important;
    }
  }
  .partner-roles {
    margin-top: 20px;
  }
}
