.text {
  white-space: pre-line;
}
.main-section {
  flex: 1;
  width: 100%;
  .company-img {
    height: 400px;
    background-color: #dcdcdc;
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
    text-decoration: center;
    margin-bottom: 20px;
    padding: 100px 440px;
  }
  h3 {
    font-weight: bold;
  }
  margin-bottom: 30px;
}
