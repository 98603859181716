.dataspace {
  background-color: white;
  color: 121212 !important;
  .edc-img {
    object-fit: contain;
    width: 100%;
    min-width: 300px;
    display: flex;
    margin: 20px auto;
    border-radius: 10px; // 모서리 둥글게 만들기 (원하는 값으로 조정)
    overflow: hidden;
  }
}

//overview
.overview {
  .dataspace {
    .dataspace-content {
      height: auto;
      
      //background-image: url(../../../assets/image/background.png);
      background-color: rgb(10, 39, 97);
      color: white;
      background-size: auto 100%;
      backg
      .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 30px;
      }
    }
  }
  .dataspace-content2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    .content-desc {
      font-size: 17px;
    }
    .architectureimg {
      margin: auto;
    }
    .share-content {
      .table {
        width: 100%;
        margin-top: 10px;
        border-collapse: collapse;
        th,
        td {
          border: 2px solid;
          padding: 30px;
          line-height: 30px;
        }
        th {
          min-width: 100px;
          font-size: 20px;
        }
        td {
          font-weight: bolder;
        }
      }
    }
  }
  .company {
    margin: 10px 0px;
    .card {
      .company-card {
        display: grid;
        grid-template-columns: 300px 1fr;
        gap: 20px;
        margin-bottom: 20px;
      }
      .company-feature-list {
        font-weight: bolder;
        li {
          margin: 5px 0px;
        }
      }
    }
    .lighthouse {
      margin-top: 20px;
      table {
        margin-top: 10px;
        th {
          padding: 10px;
          background-color: #777777;
        }
        td {
          background-color: #fff;
          text-align: center;
          padding: 20px;
          color: black;
          p {
            line-height: 30px;
          }
          a {
            color: #0a2761 !important;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
  .point {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

@media (max-width: 1200px) {
  .overview {
    .dataspace {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .company {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .dataspace-content2 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .lighthouseimg {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (max-width: 650px) {
  .company-card {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .lighthouseimg {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

.feature {
  .featurebox {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    .card {
      border: 1px solid #dddddd;
      padding: 15px;
      border-radius: 8px;
    }
  }
}
@media (max-width: 1200px) {
  .feature {
    .featurebox {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media (max-width: 650px) {
  .feature {
    .featurebox {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
//dsapplicationfield
.aplicationfield-section {
  .aplicationfieldbox {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    gap: 10px;
    .card {
      border: 1px solid #dddddd;
      padding: 15px;
      border-radius: 8px;
    }
  }
}
@media (max-width: 1200px) {
  .aplicationfield-section {
    .aplicationfield-box {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media (max-width: 650px) {
  .aplicationfield-section {
    .aplicationfield-box {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
