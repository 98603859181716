.swiper {
  z-index: 300;
}

.swiper-list {
  display: flex;
  justify-content: flex-start;
}
.swiper-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  .section-title {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 15px;
  }
}
.slide {
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  text-align: center;
  height: 400px;
  background-color: #eee;
  border-radius: 16px;
}
.wrapper {
  margin: 0px 0px;
  position: relative;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: transparent;
  border: 1px solid #007fff;
  opacity: 1;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
  display: flex;
}

.swiper-pagination-bullet-active {
  width: 20px;
  height: 6px;
  transition: width 0.5s;
  border-radius: 20px;
  background: #007fff;
  border: 1px solid transparent;
}
/*.swiper-slide {
  flex-shrink: 0;
  width: 600px;
  height: 100%;
  position: relative;
  display: flex;
  transition-property: transform;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 0px !important;
}*/

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  .linkbutton {
    width: 150px;
    height: 50px;
    font-size: 18px;
    color: #fff !important;
    background-color: #007fff !important;
    border-radius: 50px !important;
    border: none;
    cursor: pointer;
    margin: 0 5px;
  }
  .button {
    width: 50px;
    height: 50px;
    color: #fff !important;
    background-color: #007fff !important;
    border-radius: 50px !important;
    border: none;
    cursor: pointer;
    margin: 0 5px;
    z-index: 400;
  }
  
  svg {
    z-index: 400;
  }
}
//disable
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  display: none;
}
