.application-section {
  position: relative;
  .application {
    width: 280px;
    border: 2px solid #bcbcbc;
    border-radius: 12px;
    margin: 0px 10px;
    .info {
      padding: 20px 15px;
    }
    .image {
      display: flex;
      justify-content: center;
      margin: 20px auto;
    }
  }
  
  .item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .usecase-box {
    width: 100%;
    height: 100%;
    .container {
      padding: 20px;
    }
    .image {
      display: flex;
      justify-content: center;
      width: 80%;
      aspect-ratio: 1/1;
      img {
        width: 100%;
      }
    }
    .text {
      margin-top: 10px;
    }
  }
  .wrapper {
    .button-wrapper {
      display: none;
    }
  }
}
@media (max-width: 1350px) {
  .application-section {
    .item {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }
}
@media (max-width: 1050px) {
  .application-section {
    .item {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}
@media (max-width: 800px) {
  .application-section {
    .swiper-header{
      .button-wrapper {
        display: none;
      }
    }
    .item {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
  .usecase-box {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .wrapper {
    .button-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex !important;
      align-content: center;
      justify-content: space-between;
      top: 0;
      left: 0;
    }
  }
}
