.service-section {
  .wrapper {
    .tab {
      //width: 150px;
      font-size: 14px !important;
      .tab-image {
        width: 80px !important;
        height: 80px !important;
        background-color: #5d5d5d;
      }
    }
    .service {
      display: grid;
      grid-template-columns: 300px 2fr;
      gap: 30px;
      margin-top: 10px;
      .servicebox1,
      .servicebox2 {
        border-radius: 16px;
        min-height: 300px;
        height: 100%;
        background-color: white;
        border: 3px solid #5d5d5d;
        .servicetitle {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        .servicedetail {
          padding: 15px 20px;
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
              width: 180px;
              color: #000;
              border-color: #000;
              border-radius: 16px;
            }
          }
          .content-box {
            background-color: #f5f5f5;
            margin-top: 15px;
            min-height: 220px;
            height: 100%;
            border-radius: 16px;
          }
        }
      }
    }
  }
}
.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 3;
}
@media (max-width: 900px) {
  .service-section .wrapper .service {
    display: block;
    .servicebox1 {
      display: none !important;
    }
  }
}