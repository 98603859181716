html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  //min-height: 100vh;
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
  font-weight: 300 !important;
  margin: auto !important;
}

a {
  text-decoration: none !important;
  font-weight: bold !important;
  color: black !important;
}
#app {
  overflow-x: hidden;
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  //min-height: 100vh;
  word-break: keep-all;
  white-space: pre-line;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p {
    font-family: "Pretendard Variable", Pretendard, -apple-system,
      BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
      "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
    word-break: keep-all;
    white-space: pre-line;
  }
}
main {
  flex:1;
  display: flex;
  flex-direction: column;
  padding: 0px;
  .header-button {
    margin-left: 10px;
    margin-top: 70px;
    min-width: 300px;
    border-color: white;
    color: white;
  }
  input p::placeholder,
  input span ::placeholder,
  fieldset {
    line-height: 0px !important;
  }
  p {
    line-height: 1.7 !important;
  }
}
section {
  margin: 0 auto;
  max-width: 1490px !important;
  width: 100%;
  padding: 30px;
  .section-title {
    font-size: 20px;
    padding: 30px 30px;
    text-align: center;
    position: relative;
    font-weight: bold;
    font &::after {
      content: "";
      width: 70px;
      border-bottom: 2px solid;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.inquiry-button {
  background-color: white !important;
  border: 2px solid #0a2761 !important;
  border-radius: 10px !important;
  color: black !important;
  font-size: 14px !important;
  height: 40px;
  &:hover {
    background-color: #0a2761 !important;
    color: white !important;
  }
}

.tab-image {
  background-size: contain !important;
  width: 60px !important;
  height: 60px !important;
  margin-bottom: 15px !important;
}

.navigation-bar {
  width: 100%;
  height: 80px;
  background-color: rgba(15, 113, 203, 0.05) !important;
  display: flex;
  .navigation-item {
    z-index: 9;
    max-width: 1490px;
    width: 100%;
    margin: 0px auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    button {
      color: #0a2761;
      font-size: 16px;
    }
  }
}

.item-tabs {
  background-color: #0a2761 !important;
  .item-tablist,
  .item-tablist button {
    border: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    color: #cccccc !important;
    &:hover {
      color: #fff !important;
    }
    > div button {
      padding: 12px 16px;
      cursor: default !important;
    }
  }
}
.dataspace-tab {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  display: grid;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  button {
    background-color: #ffffff;
    font-size: 18px;
    font-weight: bolder;
    border-radius: 0px !important;
    border-bottom: 2px solid #aeaeae;
  }
}

@media (max-width: 1060px) {
  #app>main {
    margin-top: 56px;
    justify-content: center;
  }
  
  .inquiry-button {
    font-size: 10px !important;
  }
}


@media (max-width: 800px) {
  #app>main {
    justify-content: center;
  }
}