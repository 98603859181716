.useCase {
  .item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .usecase-box {
    border: 2px solid black;
    height: 100%;
    .image {
      display: flex;
      justify-content: center;
      padding: 30px;
      img {
        height: 200px;
      }
    }
    &:hover {
      border-color: #bcbcbc;
    }
  }
}
@media (max-width: 1350px) {
  .useCase {
    .item {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }
}
@media (max-width: 1050px) {
  .useCase {
    .item {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}
@media (max-width: 800px) {
  .useCase {
    .item {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}
