.service-tab {
  width: 100%;
  .service-panel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    .service-box {
      width: 100%;
      height: 230px;
      border: 2px solid #bcbcbc;
      .service-content {
        color: #6d6d6d;
        margin-top: 10px;
        text-overflow: ellipsis !important;
        overflow: hidden;
        height: 120px;
        display: -webkit-box !important;
        -webkit-line-clamp: 5 !important;
        -webkit-box-orient: vertical !important;
        h6 {
          font-size: 15px !important;
        }
      }
      &:hover {
        border-color: #0a2761;
      }
      .link {
        margin-top: 10px;
        display: flex !important;
        align-items: center !important;
        color: #808080 !important;
        &:hover {
          color: #0a2761 !important;
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .service-panel {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 500px) {
  .service-panel {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
