.introduce-section {

    background-color: #f9f9f9;
    width: 100%;
    margin-top: 20px;

    .content {
        display: flex;
        flex-direction: row;

        .invite {
            flex: 1 1 70%;
            padding-right: 50px;

            .title {
                font-size: 50px;
                font-weight: bold;
            }

            .desc {
                font-size: 20px;
                margin: 40px 0;
                line-height: 35px;

            }

            .desc_short {
                display: none;
                font-size: 20px;
                margin: 40px 0;
                line-height: 30px;
            }
        }
        .step {
            flex: 1 1 50%;
            .box {
                padding-bottom: 15px;
                .number {
                    font-weight: bold;
                    font-size: 20px;
                    color: #007fff;
                    padding: 3px 0;
                }
                .title {
                    font-weight: bold;
                    font-size: 30px;
                }
                .desc {
                    font-size: 16px;
                    margin: 10px 0;
                }
            }

        }
    }
}

@media (max-width: 800px) {
    .introduce-section {
        .content {
            flex-wrap: wrap;
            .invite {
                padding-right: 0px;
                .title {
                    font-size: 40px;
                }
                .desc {
                    display: none;
                }

                .desc_short {
                    display: block;
                }
            }
            .step {
                margin-top: 50px;
            }
        }
    }
}