.inquiry {
  padding: 0px 0px;

  section {
    padding: 0px 0px !important;
  }

  .wrapper {
    margin: 0px !important;
  }

  .inquirybox {
    padding: 30px 40px;
    border-radius: 10px;

    form {
      margin: 0px auto;
      max-width: 1000px;
      width: 100%;
    }

    .inquiryLabel {
      margin-bottom: 20px;
    }

    .inquirytitle {
      color: #121212;
      font-size: 16px;
      margin: 5px 0px;
      font-weight: bold;
    }

    .phone {
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 0px 20px;
    }

    .termbox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        height: 35px;
      }
    }
  }

  .inquiryinput {
    margin-bottom: 20px;
  }
}

.termlist {
  margin: 5px 0px !important;
  padding: 0px 25px;
  list-style: " - ";

  li {
    padding: 0px 10px;
  }
}

@media (max-width: 1000px) {
  .inquiry {
    display: block;

    .inquirybox {
      margin-bottom: 30px;

      .phone {
        grid-template-columns: none;
      }

      .termbox {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 30px;
      }
    }
  }

}