.gridbox {
  margin: 10px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media (max-width: 1000px) {
  .gridbox {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
